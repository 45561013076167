import { useUpdatingRef } from "@kablamo/kerosene-ui";
import { useEffect } from "react";
import {
  useMapInteractionsContext,
  type GetPropertiesFromFeatureFn,
} from "./MapInteractionsProvider";
import type {
  FeatureInteractionProperties,
  InteractiveMapElement,
} from "./types";
import useLayerInteractionState from "./useLayerInteractionState";

interface UseLayerInteractionsParams<P extends FeatureInteractionProperties> {
  element?: InteractiveMapElement;
  getPropertiesFromFeature: GetPropertiesFromFeatureFn<P>;
  layerId: string;
  onClick?: (properties: P) => void;
}

const useLayerInteractions = <P extends FeatureInteractionProperties>({
  element = "feature",
  getPropertiesFromFeature,
  layerId,
  onClick,
}: UseLayerInteractionsParams<P>) => {
  const context = useMapInteractionsContext<P>();

  if (context === undefined) {
    throw new Error(
      "useLayerInteractions must be used within a MapInteractionsProvider",
    );
  }

  const getPropertiesFromFeatureRef = useUpdatingRef(getPropertiesFromFeature);
  const onClickRef = useUpdatingRef(onClick);

  const { register, unregister } = context;

  useEffect(() => {
    register({
      layer: {
        getPropertiesFromFeature: getPropertiesFromFeatureRef,
        onClick: onClickRef,
        element,
        layerId,
      },
    });

    return () => {
      unregister({ layerId });
    };
  }, [
    getPropertiesFromFeatureRef,
    onClickRef,
    element,
    layerId,
    register,
    unregister,
  ]);

  const interactionState = useLayerInteractionState<P>({ layerId });

  return interactionState;
};

export default useLayerInteractions;
